export const ADMIN_PRODUCT_REQUEST = "ADMIN_PRODUCT_REQUEST";
export const ADMIN_PRODUCT_SUCCESS = "ADMIN_PRODUCT_SUCCESS";
export const ADMIN_PRODUCT_FAIL = "ADMIN_PRODUCT_FAIL";

export const GET_PRODUCT_BY_SLUG = "GET_PRODUCT_BY_SLUG";

export const NEW_PRODUCT_REQUEST = "NEW_PRODUCT_REQUEST";
export const NEW_PRODUCT_SUCCESS = "NEW_PRODUCT_SUCCESS";
export const NEW_PRODUCT_RESET = "NEW_PRODUCT_RESET";
export const NEW_PRODUCT_FAIL = "NEW_PRODUCT_FAIL";

export const GET_PRODUCT_DETAILS_REQUEST = "GET_PRODUCT_DETAILS_REQUEST";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAIL = "GET_PRODUCT_DETAILS_FAIL";



export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_RESET = "DELETE_PRODUCT_RESET";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_RESET = "UPDATE_PRODUCT_RESET";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const UPLOAD_IMAGES_REQUEST = "UPLOAD_IMAGES_REQUEST";
export const UPLOAD_IMAGES_SUCCESS = "UPLOAD_IMAGES_SUCCESS";
export const UPLOAD_IMAGES_FAIL = "UPLOAD_IMAGES_FAIL";


export const CLEAR_ERRORS = "CLEAR_ERRORS";