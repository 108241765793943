import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./DashboardOverview.css";
import MetaData from "../../components/layouts/MetaData";
// import { getAdminProduct } from "../../actions/productAction";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCashRegister, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
// import { Col, Row, Button, Dropdown, ButtonGroup } from 'react-bootstrap';

// import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
// import { PageVisitsTable } from "../../components/Tables";
// import { trafficShares, totalOrders } from "../../data/charts";
import CategoryIcon from '@mui/icons-material/Category';
import StorefrontIcon from '@mui/icons-material/Storefront';
const DashboardOverview = () => {
  const { products } = useSelector((state) => state.product);
  const category = useSelector((state) => state.category);
  console.log(category);

  return (
    <>
      <MetaData title={`Admin Dashboard`} />
    <main className="content">
      <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center py-4">
        <h1>Welcome To AdminDashboard</h1>
      </div>

      <div className="dashboardSummary">
        <div className="dashboardSummaryBox2">
          <Link to="/product/all">
          <StorefrontIcon/>
            <p className="para">Product</p>
            <p>{products && products.length}</p>
          </Link>
          <Link to="/category/create">
          <CategoryIcon />
            <p className="para">Category</p>
            <p>
              {category.categories[0] && category.categories[0].children.length}
            </p>
          </Link>
        </div>
      </div>

      {/* <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
          <SalesValueWidget
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col>
        <Col xs={12} className="mb-4 d-sm-none">
          <SalesValueWidgetPhone
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Customers"
            title="345k"
            period="Feb 1 - Apr 1"
            percentage={18.2}
            icon={faChartLine}
            iconColor="shape-secondary"
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Revenue"
            title="$43,594"
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CircleChartWidget
            title="Traffic Share"
            data={trafficShares} />
        </Col>
      </Row>

      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={8} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  <PageVisitsTable />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <TeamMembersWidget />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <ProgressTrackWidget />
                </Col>
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="mb-4">
                  <BarChartWidget
                    title="Total orders"
                    value={452}
                    percentage={18.2}
                    data={totalOrders} />
                </Col>

                <Col xs={12} className="px-0 mb-4">
                  <RankingWidget />
                </Col>

                <Col xs={12} className="px-0">
                  <AcquisitionWidget />
                </Col>
              </Row>
            </Col>
          </Row> */}
      {/* </Col>
      </Row> */}
      </main>
    </>
  );
};

export default DashboardOverview;
